<!--
   /**
      * barracksSolution.vue
      * @module {src/modules/public}
      * @desc 前端api
      * @author shiqiu
      * @date 2021-01-20
      */
-->
<template>
  <div>
    <el-row type="flex" justify="center" style="margin-top: 5vw;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;cursor: pointer">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10" class="margin_b_40">
                      <el-col :span="24">
                        <p class="bigTitle">系统组成</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">System composition</p>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-top: 20px">
                        <p style="text-align: center">
                          <img src="@/assets/barracks01.png" style="width: 80%;">
                        </p>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 10px;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;cursor: pointer">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10" class="margin_b_40">
                      <el-col :span="24">
                        <p class="bigTitle">系统三大模块</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">Three modules of the system</p>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :span="24">
                        <p style="font-size: 18px">富融智慧军营系统从功能上划分为三大模块：智慧安保、物联网管理、智能办公，实现营区内人、物、事的可视、可控和可管。实现对军营内各类设施设备运行状态、保障数据信息的动态采集、实时检测和实时跟踪，各项数据统一汇总、分析、共享、交互、和可视化展示等。</p>
                      </el-col>
                      <el-col :span="24" style="padding: 40px; margin-bottom: 20px">
                        <p style="text-align: center"><img src="@/assets/barracks02.png" style="width: 80%;"></p>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
export default {
  name: 'Login',
  components: {},
  data () {
    return {
    }
  },
  mounted () {

  },
  watch: {

  },
  methods: {

  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
body {
  background: #fff !important;
}
</style>
